import React from "react";
import Flickity from "react-flickity-component";
import SlidersStyle from "../../components/styles/slider";

export const SliderRelatedPromo = ({ children }) => {
  const flickityOptions = {
    contain: "true",
    cellAlign: "left",
    freeScroll: "true"
  };
  return (
    <div className="carousel-wrapper-related-promo">
      <div className="carousel-container-related-promo">
        <Flickity
          options={flickityOptions}
          className={"carousel"}
          elementType={"div"}
        >
          {children}
        </Flickity>
      </div>
      <SlidersStyle />
      <style jsx global>
        {`
          .carousel-wrapper-related-promo {
            max-width: 940px;
            width: 100%;
            align-self: center;
            margin-bottom: 48px;
          }
          .carousel-container-related-promo {
            margin-left: 12px;
          }
          .flickity-page-dots {
            bottom: -25px;
          }
          @media only screen and (max-width: 600px) {
            .flickity-page-dots {
              bottom: -16px;
            }
          }
        `}
      </style>
    </div>
  );
};
