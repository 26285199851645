import Link from "next/link";
import React from "react";

type BlogRelatedItemsProp = {
  blogData: any;
  title?: string;
  isAlwaysMobile?: boolean;
  isFromDashboard?: boolean;
};

const BlogRelatedItems = ({
  blogData,
  title = null,
  isAlwaysMobile = false
}: BlogRelatedItemsProp) => {
  return (
    <div
      className={`blog-related-items ${
        isAlwaysMobile ? "blog-related-items--all-mobile" : ""
      }`}
    >
      {blogData &&
        blogData.map((data, key) => {
          if (!title || data.title !== title) {
            return (
              <Link href="/[blog]" as={`/${data.slug}`} key={key}>
                <a className="blog-related-item">
                  <div className="blog-related-tag-mobile">
                    {data.tags[0].name}
                  </div>
                  <img
                    className="blog-related-img"
                    src={data.thumbnail}
                    height="100%"
                    alt="Related Blog Image"
                  />
                  <div className="blog-related-copy">
                    <div className="blog-related-tag">{data.tags[0].name}</div>
                    <div className="blog-related-title">{data.title}</div>
                  </div>
                </a>
              </Link>
            );
          }
        })}
      <style jsx>
        {`
          .blog-related-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .blog-related-item {
            display: flex;
            width: calc(50% - 8px);
            max-height: 75px;
            margin-bottom: 12px;
          }
          .blog-related-copy {
            display: flex;
            flex-direction: column;
            margin-left: 12px;
          }
          .blog-related-tag {
            color: var(--color-red);
            margin-bottom: 6px;
            font-size: 13px;
            font-weight: bold;
          }
          .blog-related-title {
            font-weight: bold;
            font-size: 14px;
          }
          .blog-related-tag-mobile {
            display: none;
          }
          .blog-related-items--all-mobile .blog-related-tag-mobile {
            display: initial;
            width: 100%;
            font-weight: bold;
            color: var(--color-red);
            font-size: 13px;
            margin-bottom: 6px;
          }
          .blog-related-items--all-mobile {
            width: 100%;
            border-bottom: 2px solid #f4f4f4;
            padding: 0 0 40px;
            flex-wrap: wrap;
          }
          .blog-related-items--all-mobile .blog-related-tag {
            display: none;
          }
          .blog-related-items--all-mobile .blog-related-item {
            width: 100%;
            border-bottom: 2px solid #f4f4f4;
            padding: 0 0 40px;
            flex-wrap: wrap;
          }
          .blog-related-items--all-mobile .blog-related-img {
            max-width: 35%;
            object-fit: cover;
          }
          .blog-related-items--all-mobile .blog-related-copy {
            width: calc(65% - 12px);
          }

          @media only screen and (max-width: 730px) {
            .blog-related-tag-mobile {
              display: initial;
              width: 100%;
              font-weight: bold;
              color: var(--color-red);
              font-size: 13px;
              margin-bottom: 6px;
            }
            .blog-related-item {
              width: 100%;
              border-bottom: 2px solid #f4f4f4;
              padding: 0 0 40px;
              flex-wrap: wrap;
            }
            .blog-related-tag {
              display: none;
            }
            .blog-related-img {
              max-width: 35%;
              object-fit: cover;
            }
            .blog-related-copy {
              width: calc(65% - 12px);
            }
          }
        `}
      </style>
    </div>
  );
};

export default BlogRelatedItems;
